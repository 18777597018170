import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { format } from 'date-fns';

const CardComponent = ({ article }) => {
  // Formatear la fecha de creación
  const formattedDate = format(new Date(article.creationDate), 'MMMM dd, yyyy');

  return (
    <Card sx={{ maxWidth: 345, flex: '1 1 calc(50% - 16px)', margin: '8px', borderRadius: 2 }}>
      <a href={`https://www.cubaconecta.com/articles/${article.nid}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <CardMedia
          component="img"
          height="200"
          image={article.imageUrl}
          alt={article.title}
        />
        <CardContent>
          <Typography variant="h6">
            {article.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formattedDate}
          </Typography>
        </CardContent>
      </a>
    </Card>
  );
};

export default CardComponent;
