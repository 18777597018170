import React from 'react';
import './App.css';
import SearchComponent from './SearchComponent';
import { Container, Typography, AppBar, Toolbar, Box } from '@mui/material';

function App() {
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#008C47', height: 55, justifyContent: 'center' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img src="https://www.cubaconecta.com/sites/default/files/cubaconecta.png" alt="Cuba Conecta" height="38" />
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Buscar en CubaConecta
        </Typography>
        <SearchComponent />
      </Container>
    </>
  );
}

export default App;
